<!-- Topbar Start -->
<div class="navbar-custom">
    <div class="container-fluid">
        <ul class="list-unstyled topnav-menu float-end mb-0">
            <!-- search menu -->
            <!-- <li class="d-none d-lg-block">
                <form class="app-search">
                    <div class="app-search-box" ngbDropdown placement="bottom-start">
                        <div class="input-group">
                            <input type="search" class="form-control" placeholder="Search..." id="topSearch"
                                ngbDropdownToggle>
                            <button class="btn input-group-text" type="submit">
                                <i class="fe-search"></i>
                            </button>
                        </div>
                        <div class="dropdown-lg noti-dropdown" aria-labelledby="topSearch" ngbDropdownMenu>
                            
                            <div class="dropdown-header noti-title">
                                <h5 class="text-overflow mb-2">Found 22 results</h5>
                            </div>

                            
                            <a ngbDropdownItem href="javascript:void(0);" class="notify-item"
                                *ngFor="let result of searchResults">
                                <i class="{{result.icon}} me-1"></i>
                                <span>{{result.text}}</span>
                            </a>

                            
                            <div class="dropdown-header noti-title">
                                <h6 class="text-overflow mb-2 text-uppercase">Users</h6>
                            </div>

                            <div class="notification-list">
                                
                                <a ngbDropdownItem href="javascript:void(0);" class="notify-item"
                                    *ngFor="let user of searchUsers">
                                    <div class="d-flex align-items-start">
                                        <img class="d-flex me-2 rounded-circle" [src]="user.profile" [alt]="user.name"
                                            height="32">
                                        <div class="w-100">
                                            <h5 class="m-0 font-14">{{user.name}}</h5>
                                            <span class="font-12 mb-0">{{user.position}}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </form>
            </li> -->

            <!-- <li class="d-inline-block d-lg-none" ngbDropdown placement="bottom-start">
                <a class="nav-link arrow-none waves-effect waves-light" href="javascript:void(0);" role="button"
                    ngbDropdownToggle>
                    <i class="fe-search noti-icon"></i>
                </a>
                <div class="dropdown-menu-end  dropdown-lg p-0 noti-dropdown" ngbDropdownMenu>
                    <form class="p-3" ngbDropdownItem>
                        <input type="text" class="form-control" placeholder="Search ..."
                            aria-label="Recipient's username">
                    </form>
                </div>
            </li> -->

            <!-- full screen button -->
            <li class="dropdown d-none d-lg-inline-block">
                <a class="nav-link dropdown-toggle arrow-none waves-effect waves-light maximize-icon"
                    href="javascript:void(0)" (click)="toggleFullScreen()">
                    <i class="fe-maximize noti-icon"></i>
                </a>
            </li>

            <!-- brands menu -->
            <li class="d-none d-lg-inline-block topbar-dropdown" ngbDropdown>
                <a class="nav-link arrow-none waves-effect waves-light" href="javascript:void(0)" ngbDropdownToggle
                    role="button">
                    <i class="fe-grid noti-icon"></i>
                </a>
                <div class="dropdown-xl dropdown-menu-end" ngbDropdownMenu>
                    <div class="p-lg-1">
                        <div class="row g-0 row-cols-3">
                            <div class="col" *ngFor="let brand of brands">
                                <a class="dropdown-icon-item" [href]="brand.link" target="_blank">
                                    <img [src]="brand.logo" [alt]="brand.name">
                                    <span>{{brand.name}}</span>
                                </a>
                            </div>
                        </div>

                    </div>

                </div>
            </li>

            <li class="d-none d-lg-inline-block topbar-dropdown" ngbDropdown>
                <a class="nav-link arrow-none waves-effect waves-light" href="javascript:void(0)" role="button" title="Todo List"
                    ngbDropdownToggle>
                    <i class="fe-edit noti-icon"></i>
                </a>
                <div class="dropdown-menu-end dropdown-xl" ngbDropdownMenu>
                    <app-widget-todo [toDoItems]="todoList" [height]="312" [archivable]="true" [addable]="true">
                    </app-widget-todo>
                    <!-- <a href="javascript:void(0);" ngbDropdownItem *ngFor="let language of languages">
                        <img [src]="language.flag" alt="user-image" class="me-1" height="12"> <span
                            class="align-middle">{{language.name}}</span>
                    </a> -->

                </div>
            </li>

            <li class="notification-list topbar-dropdown" ngbDropdown>
                <a class="nav-link waves-effect waves-light" (click)="openNotifications()" href="javascript:void(0)"
                    role="button" ngbDropdownToggle>
                    <i class="fe-bell noti-icon"></i>
                    <span class="badge bg-danger rounded-circle noti-icon-badge"
                        *ngIf="unreadNotification > 0">{{unreadNotification}}</span>
                </a>
                <div class="dropdown-menu-end dropdown-xl" ngbDropdownMenu>

                    <!-- item-->
                    <div class="noti-title" ngbDropdownItem>
                        <h5 class="m-0">
                            <span class="float-end">
                                <span class="text-dark" id="clearAll" (click)="deleteAllNotifications()">
                                    <small>Clear All</small>
                                </span>
                            </span>Notification
                        </h5>
                    </div>

                    <ngx-simplebar class="noti-scroll">
                        <!-- item -->
                        <a class="notify-item " [ngClass]="!notification.read ? 'unread' : ''"
                            [href]="notification.link" *ngFor="let notification of notificationList" ngbDropdownItem>
                            <!-- <div class="notify-icon" *ngIf="item.avatar">
                                <img [src]="item.avatar" class="img-fluid rounded-circle" alt="">
                            </div> -->
                            <!-- <div class="notify-icon bg-{{item.bgColor}}" *ngIf="item.icon">
                                <i [class]="item.icon"></i>
                            </div> -->
                            <p class="notify-details">{{notification.body}}</p>
                            <!-- <p class="text-muted mb-0 user-msg">
                                <small>{{item.subText}}</small>
                            </p> -->
                            <!-- <div class="delete-icon">
                                <i class="fe-x noti-icon"></i>
                            </div> -->
                        </a>
                    </ngx-simplebar>

                    <!-- All-->
                    <!-- <a href="javascript:void(0);" class="dropdown-item text-center text-primary notify-item notify-all">
                        View all
                        <i class="fe-arrow-right"></i>
                    </a> -->

                </div>
            </li>

            <!-- profile menu -->
            <li class="dropdown notification-list  topbar-dropdown" ngbDropdown>
                <a ngbDropdownToggle class="nav-link nav-user  me-0 waves-effect waves-light" id="profileMenuDropdown"
                    href="javascript:void(0)" aria-expanded="false">
                    <span class="account-user-avatar">
                        <img [src]="loggedInUser.avatar" alt="user-image" class="rounded-circle">
                    </span>
                    <span class="pro-user-name ms-1">
                        {{loggedInUser.firstName}} <i class="mdi mdi-chevron-down"></i>
                    </span>

                </a>
                <div ngbDropdownMenu aria-labelledby="profileMenuDropdown" class="dropdown-menu-end profile-dropdown">
                    <!-- item -->
                    <div ngbDropdownItem class="dropdown-header noti-title">
                        <h6 class="text-overflow m-0">Welcome !</h6>
                    </div>
                    <!-- item -->
                    <a *ngFor="let option of profileOptions;" class="notify-item" ngbDropdownItem>
                        <i class="{{option.icon}} me-1"></i>
                        <span style="cursor: pointer;" [routerLink]="option.redirectTo">{{option.label}}</span>
                    </a>
                </div>
            </li>

            <!-- right menu button -->
            <!-- <li class="dropdown notification-list">
                <a href="javascript:void(0);" class="nav-link right-bar-toggle waves-effect waves-light"
                    (click)="toggleRightSidebar()">
                    <i class="fe-settings noti-icon"></i>
                </a>
            </li> -->
        </ul>

        <!-- LOGO -->
        <div class="logo-box">
            <a [routerLink]="'../dashboard-1'" class="logo logo-dark text-center">
                <span class="logo-sm">
                    <img src="../assets/images/logo-sm.png" alt="" height="22">
                </span>
                <span class="logo-lg">
                    <img src="../assets/images/logo-dark.png" alt="" height="20" *ngIf="layoutType!=='two-column'">
                    <img src="../assets/images/logo-dark-2.png" alt="" height="20" *ngIf="layoutType==='two-column'">
                </span>
            </a>

            <a [routerLink]="'../dashboard-1'" class="logo logo-light text-center">
                <span class="logo-sm">
                    <img src="../assets/images/logo-sm.png" alt="" height="22">
                </span>
                <span class="logo-lg">
                    <img src="../assets/images/logo-light.png" alt="" height="60" *ngIf="layoutType!=='two-column'">
                    <img src="../assets/images/logo-light-2.png" alt="" height="60" *ngIf="layoutType==='two-column'">
                </span>
            </a>
        </div>

        <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
            <li>
                <button class="button-menu-mobile waves-effect waves-light d-none d-lg-block"
                    (click)="changeSidebarWidth()" *ngIf="layoutType!=='horizontal'">
                    <i class="fe-menu"></i>
                </button>
            </li>

            <li>
                <button class="button-menu-mobile open-left d-block d-lg-none waves-effect waves-light"
                    *ngIf="layoutType!=='horizontal'" (click)="toggleMobileMenu($event)">
                    <i class="fe-menu"></i>
                </button>
            </li>

            <li>
                <!-- Mobile menu toggle (Horizontal Layout)-->
                <a class="navbar-toggle nav-link" [ngClass]="{'open': topnavCollapsed}"
                    (click)="toggleMobileMenu($event)" *ngIf="layoutType==='horizontal'">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
                <!-- End mobile menu toggle-->
            </li>

            <!-- <li class="d-none d-xl-block" ngbDropdown>
                <a class="nav-link waves-effect waves-light" href="javascript:void(0)" role="button" ngbDropdownToggle>
                    Create New
                    <i class="mdi mdi-chevron-down"></i>
                </a>
                <div ngbDropdownMenu>
                    <ng-container *ngFor="let option of createMenuOptions;let i=index">
                        <div class="dropdown-divider" *ngIf="i===createMenuOptions.length-1"></div>
                        <a href="javascript:void(0);" ngbDropdownItem>
                            <i class="{{option.icon}} me-1"></i>
                            <span>{{option.label}}</span>
                        </a>
                    </ng-container>
                </div>
            </li>

            <li class="dropdown-mega d-none d-xl-block" ngbDropdown>
                <a class="nav-link waves-effect waves-light" href="javascript:void(0)" role="button" ngbDropdownToggle>
                    Mega Menu
                    <i class="mdi mdi-chevron-down"></i>
                </a>
                <div class="dropdown-megamenu" ngbDropdownMenu>
                    <div class="row">
                        <div class="col-sm-8">
                            <div class="row">
                                <div class="col-md-4" *ngFor="let menu of megaMenuItems">
                                    <h5 class="text-dark mt-0">{{menu.menuTitle}}</h5>
                                    <ul class="list-unstyled megamenu-list" *ngIf="menu.menuItems">
                                        <li *ngFor="let item of menu.menuItems">
                                            <a href="javascript:void(0);">{{item}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="text-center mt-3">
                                <h3 class="text-dark">Special Discount Sale!</h3>
                                <h4>Save up to 70% off.</h4>
                                <button class="btn btn-primary rounded-pill mt-3">Download Now</button>
                            </div>
                        </div>
                    </div>

                </div>
            </li> -->
        </ul>
        <div class="clearfix"></div>
    </div>
</div>
<!-- end Topbar -->