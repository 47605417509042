import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private toast: ToastController, private alert: AlertController) { }

  presentToast(text: string,color = 'dark') {
    this.toast.create({
      message: text,
      duration: 3000,
      position: 'top',
      color
    }).then(toast => toast.present());
  }

  // copy text to clipboard
  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
  }

  presentAlertError(text: string) {
    this.alert.create({
      header: 'Error',
      message: text,
      buttons: ['OK']
    }).then(alert => alert.present());
  }

  encodeString(text: string){
    return encodeURIComponent(text);
  }

  
}
