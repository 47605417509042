<div class="card" id="tooltip-container">
    <div class="card-body">
        <i class="fa fa-info-circle text-muted float-end" placement="bottom" ngbTooltip="More Info"></i>
        <h4 class="mt-0 font-16">{{title}}</h4>
        <h2 class="text-primary my-3 text-center">
            <span [countUp]="stats!" [options]="options!">{{stats |
                number}}</span>
        </h2>
        <p class="text-muted mb-0">{{trendLabel}}: {{trendValue}} <span class="float-end"><i
                    class="{{trendIcon}} text-{{variant}} me-1"></i>{{trendStats}}%</span></p>
    </div>
</div>