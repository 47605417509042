import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { JoyrideModule } from 'ngx-joyride';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { FakeBackendProvider } from './core/helpers/fake-backend';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { LayoutModule } from './layout/layout.module';
import { IonicModule } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { AuthGuard } from './core/guards/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { InfoButtonComponent } from './components/info-button/info-button.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { NotificationCardComponent } from './components/notification-card/notification-card.component';

const config: SocketIoConfig = { url: environment.socketUrl, options: { transports: ['websocket'] } };

@NgModule({
  declarations: [
    AppComponent,
    NotificationCardComponent,
    // InfoButtonComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    JoyrideModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    SocketIoModule.forRoot(config),
    SweetAlert2Module.forRoot(),
    AppRoutingModule,
    LayoutModule,
    IonicModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule,
    OverlayModule
  ],
  providers: [
    Title,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // provider used to create fake backend
    // FakeBackendProvider,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
