<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-6">
                <div class="avatar-sm bg-{{variant}} rounded">
                    <i class="{{icon}}  text-white" [ngClass]="{'avatar-title font-22':icon }"></i>

                </div>
            </div>
            <div class="col-6">
                <div class="text-end">
                    <h3 class="text-dark my-1"><span [countUp]="stats!" [options]="options!">{{stats |
                            number}}</span></h3>
                    <p class="text-muted mb-1 text-truncate">{{description}}</p>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <h6 class="text-uppercase">Target <span class="float-end">{{progress}}%</span></h6>
            <ngb-progressbar [type]="variant" [value]="progress" class="progress-sm m-0">
            </ngb-progressbar>
        </div>
    </div>
</div> <!-- end card-->