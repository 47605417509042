<div class="card">
    <div class="card-body">
        <div class="float-end" ngbDropdown>
            <a href="javascript:void(0)" class="arrow-none card-drop" id="messageDropdown" aria-expanded="false"
                ngbDropdownToggle>
                <i class="mdi mdi-dots-vertical"></i>
            </a>
            <div class="dropdown-menu-end" ngbDropdownMenu aria-labelledby="messageDropdown">
                <!-- item-->
                <a ngbDropdownItem>Settings</a>
                <!-- item-->
                <a ngbDropdownItem>Action</a>
            </div>
        </div>
        <h4 class="header-title mb-3">Chat</h4>

        <div class="chat-conversation">
            <ngx-simplebar style="height: 350px;">
                <ul class="conversation-list">
                    <li class="clearfix" *ngFor="let message of chatMessages;"
                        [ngClass]="{'odd': message.sender.name==='Dominic'}">
                        <div class="chat-avatar">
                            <img [src]="message.sender.avatar" alt="user">
                            <i>{{message.time}}</i>
                        </div>
                        <div class="conversation-text">
                            <div class="ctext-wrap">
                                <i>{{message.sender.name}}</i>
                                <p>
                                    {{message.message}}
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </ngx-simplebar>

            <form class="needs-validation mt-3" name="chat-form" #chatForm="ngForm" (ngSubmit)="sendMessage()">
                <div class="row">
                    <div class="col">
                        <input type="text" class="form-control chat-input" placeholder="Enter your text" required
                            name="new-message" [(ngModel)]="newMessage"
                            [ngClass]="{'is-invalid': submitted && newMessage===''}">
                        <div class="invalid-feedback">
                            Please enter your messsage
                        </div>
                    </div>
                    <div class="col-auto">
                        <button type="submit" class="btn btn-danger chat-send waves-effect waves-light">Send</button>
                    </div>
                </div>
            </form>

        </div> <!-- end .chat-conversation-->
    </div>
</div> <!-- end card-->