<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-6">
                <div class="avatar-sm bg-{{variant}} rounded-circle">
                    <i class="{{icon}}" [ngClass]="{'avatar-title font-22 text-white':icon }"></i>
                </div>
            </div>
            <div class="col-6">
                <div class="text-end">
                    <h3 class="my-1"><span [countUp]="stats" [options]="options">{{stats | number}}</span></h3>
                    <p class="text-muted mb-1 text-truncate">{{title}}</p>
                </div>
            </div>
        </div>

        <div class="mt-4">
            <ng-content></ng-content>
        </div>
    </div>
</div> <!-- end card-->