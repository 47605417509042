import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiCallService } from 'src/app/services/api-call.service';

import { AuthenticationService } from '../service/auth.service';
import { SocketService } from '../service/socket.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private apiCall: ApiCallService,
        private socketService: SocketService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let currentUser = this.authenticationService.currentUser();
        if (currentUser) {
            this.socketService.connect();
            this.socketService.on('currentUser', (data: any) => {
                data.avatar = 'assets/images/users/avatar.svg';
                sessionStorage.setItem('currentUser', JSON.stringify(data));
                this.authenticationService.user = data
            })
            this.socketService.emit('fetchCurrentUser', {});
            return true;
        }


        // not logged in so redirect to login page with the return url
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}