<div class="widget-rounded-circle card">
    <div class="card-body">
        <div class="row">
            <div class="col-6">
                <div class="avatar-lg {{iconContainerClass}}">
                    <i class="{{icon}} text-{{variant}}" [ngClass]="{'font-24 avatar-title': icon}"></i>
                </div>
            </div>
            <div class="col-6">
                <div class="text-end">
                    <h3 class="text-dark mt-1"><span [countUp]="stats!" [options]="options!">{{stats | number}}</span>
                    </h3>
                    <p class="text-muted mb-1 text-truncate">{{description}}</p>
                </div>
            </div>
        </div> <!-- end row-->
    </div>
</div> <!-- end widget-rounded-circle-->