import { Component, OnInit } from '@angular/core';
import { SocketService } from 'src/app/core/service/socket.service';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {
  notifications: any[] = [];

  constructor(private socketService: SocketService) {}

  ngOnInit(): void {
    this.socketService.on('cardCreated', (notification: any) => {
        this.addNotification(notification);
    });

    this.socketService.on('notificationCards', (notifications: any[]) => {
        notifications.forEach(notification => {
          this.addNotification(notification);
        });
      });
  }

  addNotification(notification: any) {
    if (!this.notifications.some(n => n._id === notification._id)) {
      this.notifications.push(notification);
    }
  }

  removeNotification(notification: any) {
    this.notifications = this.notifications.filter(n => n !== notification);
  }

  closeNotification(notification: any) {
    this.removeNotification(notification);
  }
}
