/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Socket } from 'ngx-socket-io';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(private socket: Socket, private authService: AuthenticationService, private toast: ToastController,
    private router: Router) { }

  connect() {
    this.socket.connect();
    this.socket.emit('join', { user: this.authService.currentUser()?._id });
    this.emit('ping', { user: this.authService.currentUser() });
    this.on('pong', (data: any) => {
      console.log('pong', data);
    });
    this.on('error', async (data: any) => {
    //   if (data === 'unauthorized') {
    //     sessionStorage.removeItem('currentUser');
    //     localStorage.removeItem('currentUser');
    //     this.router.navigate(['/auth/login'], { queryParams: { returnUrl: '/dashboard' } });
    //   }
      this.toast.create({
        message: data,
        duration: 2000
      }).then(toastEl => {
        toastEl.present();
      });
    });

    this.emit('fetchNotificationCards', { user: this.authService.currentUser()?._id });
    this.on('notificationCards', (cards: any) => {
        cards.forEach((card: any) => {
          this.showNotificationCard(card);
        });
      });

    this.socket.on('navigate', (data: any) => {
      console.log('navigate', data);
    });
  }

  async emit(action: string, data = {}) {
    // let user = this.userService.user;
    if (!this.authService.currentUser()) {
      // user = await this.userService.loadUser(false);
      return;
    }
    const sendData = Object.assign(data, { user: this.authService.currentUser()?._id });
    this.socket.emit(action, sendData);

  }

  off(action: string) {
    this.socket.removeAllListeners(action);
  }

  async on(action: string, callback: Function) {
    this.socket.on(action, callback);
  }
  showNotificationCard(card: any) {
    // Logic to display the notification card
    // You may need to implement this method to show the card in your UI
  }
}
