<div class="notification-container">
    <div *ngFor="let notification of notifications" class="notification-card">
      <div class="card">
        <div class="card-header">
          Notification
          <button class="close" (click)="closeNotification(notification)">&times;</button>
        </div>
        <div class="card-body">
          {{ notification.body }}
        </div>
      </div>
    </div>
  </div>
  