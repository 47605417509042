<!-- Todo-->
<div class="card">
    <div class="card-body">
        <!-- <div class="float-end" ngbDropdown>
            <a href="javascript:void(0)" class="arrow-none card-drop" id="messageDropdown" aria-expanded="false"
                ngbDropdownToggle>
                <i class="mdi mdi-dots-vertical"></i>
            </a>
            <div class="dropdown-menu-end" ngbDropdownMenu aria-labelledby="messageDropdown">
                
                <a ngbDropdownItem>Settings</a>
                
                <a ngbDropdownItem>Action</a>
            </div>
        </div> -->
        <h4 class="header-title mb-2">Todo List - Keep Track of your todo's</h4>

        <!-- todo start -->
        <div class="todoapp">

            <div class="row" *ngIf="archivable">
                <div class="col">
                    <h5 id="todo-message"><span id="todo-remaining">{{getInprogressTodoCount()}}</span> of <span
                            id="todo-total">{{toDoItems.length}}</span>
                        remaining</h5>
                </div>
                <div class="col-auto">
                    <a href="javascript:void(0)" (click)="archiveTasks()" class="float-end btn btn-light btn-sm"
                        id="btn-archive">Archive</a>
                </div>
            </div>
            <ngx-simplebar [style.height.px]="height">
                <ul class="list-group list-group-flush todo-list" id="todo-list">
                    <li class="list-group-item border-0 ps-0" *ngFor="let todo of toDoItems">
                        <div class="form-check mb-0" *ngIf="todo.completed">
                            <input type="checkbox" class="form-check-input todo-done" [id]="todo._id" checked
                                (change)="toggleTodo(todo)" />
                            <label class="form-check-label" [for]="todo._id"><s>{{todo.text}}</s></label>
                        </div>

                        <div class="form-check mb-0" *ngIf="!todo.completed">
                            <input type="checkbox" class="form-check-input" [id]="todo._id"
                                (change)="toggleTodo(todo)" />
                            <label class="form-check-label" [for]="todo._id">{{todo.text}}</label>
                        </div>
                    </li>
                </ul>
            </ngx-simplebar>


            <form name="todo-form" class="mt-3" #addTodo="ngForm" (ngSubmit)="addNewTask()" *ngIf="addable">
                <div class="row">
                    <div class="col">
                        <input type="text" id="todo-input-text" name="todo-input-text" class="form-control"
                            placeholder="Add new todo" required [(ngModel)]="newTask"
                            [ngClass]="{'is-invalid': submitted && newTask === '' || newTask===' '}">
                        <div class="invalid-feedback">
                            Please enter your task name
                        </div>
                    </div>
                    <div class="col-auto">
                        <button class="btn-primary btn-md btn waves-effect waves-light" type="submit"
                            id="todo-btn-submit">Add</button>
                    </div>
                </div>
            </form>
        </div>
        <!-- todo end -->
    </div> <!-- end card-body -->
</div> <!-- end card-->